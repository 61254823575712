import * as React from "react"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { SEO } from "../components/seo"

const sliderSettings = {
  dots: false,
  infinite: true,
  autoplay: true,
	arrows: true,
}

const DogadjajiPage = () => {
  return (
    <Layout
      backArrow={true}
      bodyClasses={" flat-menu-page -white-third-bg meny-right "}
    >
      <Breadcrumbs currentPage="Događaji" />
      <main className="main-content center-aligned">
        <div className="container">
          <h1 className="title-of-page">Događaji</h1>

					<section className="company-info-section -bottom-indent">
						<div className="row -bottom-indent">
							<div className="col-12 col-lg-12">
                <div style={{position: 'relative', paddingBottom: '56.25%', height: '0', backgroundColor: '#e7e7e7'}}>
                  <iframe style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}} width="560" height="315" src="https://www.youtube.com/embed/-Vi7UsPh7hI " title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
							</div>
						</div>

						<div className="row">
							<div className="col-12 about__company-content">
								<h2 className="title-section title-accent">Svaki dan je događaj. Ako je na pravom mestu!</h2>

								<div className="text-block -no-margin">
                  <p>Stari Dorćol je mesto uskih ulica niz koje se nižu stare, male kuće i predratne zgrade.</p>
                  <p>A u sred toga - Barrel House, sa širinom da prihvati i realizuje svaku vašu ideju za korporativni događaj, privatnu proslavu ili bilo koji drugi event.</p>
                  <p>Restoran, koktel bar i jedna od najprostranijih bašti Dorćola, zajedno mogu da ugoste čak 600 gostiju i pruže im nezaboravno iskustvo. Prostor je koncipiran tako da se može prilagoditi vašim željama i potrebama, a naše pažljivo birano osoblje učiniće sve da to bude događaj za pamćenje!</p>
                  <p>Telefon za sve dodatne informacije i rezeravacije termina: <a href="tel:00381604466039">060/44-66-039</a></p>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12 col-lg-12">

                <h2 className="title-section title-accent" style={{marginTop: '2rem', width: '100%'}}>KK Partizan - proslava povodom 30 godina od osvajanja evroligaške titule</h2>
                <Slider {...sliderSettings}
                  style={{
                    marginBottom: '3rem'
                  }}
                  >
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/kk-partizan-30-god-evrolige/043A9068-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/kk-partizan-30-god-evrolige/043A9074-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/kk-partizan-30-god-evrolige/043A9093-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/kk-partizan-30-god-evrolige/043A9138-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/kk-partizan-30-god-evrolige/043A9216-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/kk-partizan-30-god-evrolige/043A9225-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/kk-partizan-30-god-evrolige/043A9235-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/kk-partizan-30-god-evrolige/043A9251-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/kk-partizan-30-god-evrolige/043A9317-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/kk-partizan-30-god-evrolige/043A9332-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                </Slider>

                <h2 className="title-section title-accent" style={{marginTop: '2rem', width: '100%'}}>Ducati Apericena Event</h2>
                <Slider {...sliderSettings}
                  style={{
                    marginBottom: '3rem'
                  }}
                  >
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Ducati-Apericena-Event/043A3738-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Ducati-Apericena-Event/043A3739-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Ducati-Apericena-Event/043A3790-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Ducati-Apericena-Event/043A3828-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Ducati-Apericena-Event/043A3844-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Ducati-Apericena-Event/043A3861-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Ducati-Apericena-Event/043A3905-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Ducati-Apericena-Event/043A3914-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Ducati-Apericena-Event/043A3944-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Ducati-Apericena-Event/043A4014-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                </Slider>

                <h2 className="title-section title-accent" style={{marginTop: '2rem', width: '100%'}}>DigiTalk Connect Event</h2>
                <Slider {...sliderSettings}
                  style={{
                    marginBottom: '3rem'
                  }}
                  >
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/DigiTalk-Connect-Event/247706831_285694350081362_5146311792920303997_n-1140x759.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/DigiTalk-Connect-Event/247836103_285693673414763_1477790155221504688_n-1140x759.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/DigiTalk-Connect-Event/248021091_285695746747889_8776104836406841856_n-1140x759.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/DigiTalk-Connect-Event/248046018_285699103414220_1050874698137644754_n-1140x759.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/DigiTalk-Connect-Event/248081948_285698923414238_8589279327153023990_n-1140x759.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/DigiTalk-Connect-Event/248184399_285696970081100_1598843435090643202_n-1140x759.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/DigiTalk-Connect-Event/248213024_285693310081466_9175958362738923338_n-1140x759.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/DigiTalk-Connect-Event/248376141_285700813414049_3936840911661422770_n-1140x759.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/DigiTalk-Connect-Event/248549222_285697706747693_1963691070012274077_n-1140x759.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/DigiTalk-Connect-Event/248694243_285697250081072_1647913264425715673_n-1140x759.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/DigiTalk-Connect-Event/248774658_285699040080893_847098554196454421_n-1140x759.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/DigiTalk-Connect-Event/249029181_285697736747690_7892355339584418629_n-1140x759.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                </Slider>


                <h2 className="title-section title-accent" style={{marginTop: '2rem', width: '100%'}}>Empple festival</h2>
                <Slider {...sliderSettings}
                  style={{
                    marginBottom: '3rem'
                  }}
                  >
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Empple-festival/01-10-EMPPLE-116-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Empple-festival/01-10-EMPPLE-129-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Empple-festival/01-10-EMPPLE-133-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Empple-festival/01-10-EMPPLE-135-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Empple-festival/01-10-EMPPLE-171-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Empple-festival/01-10-EMPPLE-185-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Empple-festival/01-10-EMPPLE-248-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Empple-festival/01-10-EMPPLE-268-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Empple-festival/01-10-EMPPLE-32-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Empple-festival/01-10-EMPPLE-56-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Empple-festival/01-10-EMPPLE-66-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Empple-festival/01-10-EMPPLE-67-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Empple-festival/01-10-EMPPLE-72-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                  <div>
                    <StaticImage src="../images/dogadjaji-slajder/Empple-festival/01-10-EMPPLE-96-1140x760.jpg" alt="" width={1140} placeholder="blurred" quality="85" />
                  </div>
                </Slider>

              </div>
            </div>

					</section>

        </div>
      </main>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)

export default DogadjajiPage
